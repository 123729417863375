html,
body {
  margin: 0;
}

body {
  background: linear-gradient(#e8e8e8, #e0e0e0);
}

.wrapper {
  margin-top: 100px;
  padding-top: 150px;
  padding-bottom: 1000px;
  max-width: 700px;
  margin: 0 auto;
}
